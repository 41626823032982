<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="2"/>
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Wǒ yǒu yí ge mèimei (shàng)",
        hanzi: "我有一个妹妹 (上)",
        english: "I Have A Sister (I)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-11-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>