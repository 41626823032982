<template>
  <div class="recognition-page-one">
    <RecognitionPage :backgroundImage="bgImg" :wordObj="wordDetail" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/12-Vocabulary/lesson-11-you.svg"),
      wordDetail: {
        pinName: "yǒu",
        chineseName: "有",
        englishName: "to have",
        songUrl: require("@/assets/audio/L1/5-Words/you3.mp3"),
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-one {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>